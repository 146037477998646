import React from 'react';
import { Row, Image, Spinner, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  getSubStringBeforeChar,
  redirectToReservationWithoutParams,
  uuidV4
} from '../../../services/utils';

const routesData = [
  {
    id: 'Puerto Montt - Puerto Natales',
    port: 'Pto. Montt',
    code: 'PMC - NAT'
  },
  {
    id: 'Puerto Natales - Puerto Montt',
    port: 'Pto. Natales',
    code: 'NAT - PMC'
  }
];

const AvailableItineraries = ({
  availableDates,
  tourismSeasonStart,
  tourismSeasonEnd,
  aditionalStyles = {},
  shipImage,
  isCargo = false
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleRedirect = () => {
    if (isCargo) {
      history.push('/cargo/contact');
    } else {
      redirectToReservationWithoutParams();
    }
  };

  const evaluateDataShown = formatedDate => {
    let showData = true;
    if (isCargo) {
      showData = formatedDate > Date.now();
    } else {
      showData =
        tourismSeasonEnd > formatedDate &&
        formatedDate > tourismSeasonStart &&
        formatedDate > Date.now();
    }
    return showData;
  };

  return (
    <Row
      id="second-section"
      className={`second-section ${
        isCargo ? 'second-section-cargo' : 'second-section-tourism'
      } align-items-center px-0`}
      style={aditionalStyles}
    >
      <Col md={4} className="d-flex ship-box justify-content-start">
        <Image
          src={shipImage}
          alt="Logo barco"
          className="ship-img"
          style={{ marginLeft: '10px' }}
        />
      </Col>
      <Col className="departures-box">
        <h4
          className={`mb-3 departures-box-title ${
            isCargo ? '' : 'departures-box-white-title'
          }`}
        >
          {t('tourism.landing.nextDates.title')}
        </h4>
        <div
          className={`next-departures-box ${
            isCargo ? 'next-departures-box-cargo' : ''
          } pl-4`}
          style={{ overflowY: 'auto' }}
        >
          {availableDates.length ? (
            availableDates.map(date => {
              const formatedDate = new Date(date.fechaZarpe);
              const selectedRoute = routesData.find(
                availableRoute => date.routeDestination === availableRoute.id
              );
              return evaluateDataShown(formatedDate) ? (
                <div
                  key={uuidV4()}
                  className="box-counts"
                  aria-hidden="true"
                  onClick={() => handleRedirect()}
                >
                  <h3 className="mb-0">{formatedDate.getDate()}</h3>
                  <div className="d-flex mb-0 mt-0">
                    <p className="mr-2 mb-0 mt-0">
                      {formatedDate.toLocaleString('default', {
                        month: 'long'
                      })}{' '}
                      -{' '}
                      {formatedDate.toLocaleString('default', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                      })}
                    </p>
                  </div>
                  <p className="mb-0 mt-0">
                    {getSubStringBeforeChar(date.routeDestination, '-')}
                  </p>
                  <p className="destination mb-1 mt-2">
                    {selectedRoute?.code || date.routeDestination}
                  </p>
                </div>
              ) : (
                undefined
              );
            })
          ) : (
            <div className="containerSpinnerLoad center-spinner">
              <Spinner
                animation="border"
                variant={isCargo ? 'black' : 'primary'}
              />
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default AvailableItineraries;
