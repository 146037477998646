import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet-async';
import defaultShareImage from '../../../assets/images/default.png';

export default function SEO({
  title = 'Vive la Patagonia',
  description = 'Descubre por qué viajar en Patagonia, sus mejores destinos y las actividades que hacer en el Sur de Chile.',
  author = 'Navimag Ferries',
  ogType = 'blog',
  twitterCard = 'summary',
  image = defaultShareImage,
  imageAlt = '',
  imageWidth = 2500,
  imageHeight = 1200,
  url = 'https://www.navimag.com/blog/es',
  contentLanguage = 'es',
  showSpanishChatbot = false
}) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="og:image:alt" content={imageAlt} />
      <meta property="og:url" content={url} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
      {/* Pinterest tags */}
      <meta
        name="p:domain_verify"
        content={process.env.REACT_APP_PINTEREST_DOMAIN_VERIFY}
      />
      <meta
        name="msvalidate.01"
        content={process.env.REACT_APP_PINTEREST_MSVALIDATE}
      />
      <meta
        name="ahrefs-site-verification"
        content={process.env.REACT_APP_PINTEREST_SITE_VERIFICATION}
      />
      {/* End Pinterest tags */}

      <meta
        name="google-site-verification"
        content={process.env.REACT_APP_GOOGLE_SITE_VERIFICATION}
      />
      <meta httpEquiv="content-language" content={contentLanguage} />
      {showSpanishChatbot &&
        process.env.REACT_APP_PIPEDRIVE_CHATBOT_COMPANY_ID &&
        process.env.REACT_APP_PIPEDRIVE_LANDING_SPANISH_PLAYBOOK_UUID &&
        process.env.REACT_APP_PIPEDRIVE_LANDING_SPANISH_PLAYBOOK_VERSION && (
          <>
            <script type="text/javascript">
              {`window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: ${process.env.REACT_APP_PIPEDRIVE_CHATBOT_COMPANY_ID},playbookUuid: '${process.env.REACT_APP_PIPEDRIVE_LANDING_SPANISH_PLAYBOOK_UUID}',version: ${process.env.REACT_APP_PIPEDRIVE_LANDING_SPANISH_PLAYBOOK_VERSION}};
          (function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();`}
            </script>
            <script
              src="https://leadbooster-chat.pipedrive.com/assets/loader.js"
              async
            />
          </>
        )}
    </Helmet>
  );
}
