import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FormBookTrip, IcoMoon } from '../../../components';
import Slides from '../../../components/Utils/Slides';
import { indexSlidesRequest } from '../../../requests/slides';

const FirstSection = ({
  isDesktop,
  setShowModal,
  handleCreateRequest,
  title,
  subtitle,
  dontShowCargo = false,
  slideType = 'principal_banner'
}) => {
  const history = useHistory();
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = localStorage.getItem('language') || 'es';
  const { settings } = useSelector(state => state.utils);
  const [needCargoText, setNeedCargoText] = useState(undefined);
  const [slideTitle, setSlideTitle] = useState('');
  const [slideSubtitle, setSlideSubtitle] = useState('');
  const [finishedFirstFetch, setFinishedFirstFetch] = useState(false);
  const [noSlideFound, setNoSlideFound] = useState(false);

  const updateSlideTextValues = () => {
    if (slides.length !== 0 && !noSlideFound) {
      const {
        heroTitleEs,
        heroTitleEn,
        heroSubtitleEs,
        heroSubtitleEn,
        heroButtonTextEs,
        heroButtonTextEn
      } = slides[0];
      setSlideTitle(language === 'en' ? heroTitleEn : heroTitleEs);
      setSlideSubtitle(language === 'en' ? heroSubtitleEn : heroSubtitleEs);
      const buttonText =
        language === 'en' ? heroButtonTextEn : heroButtonTextEs;
      const buttoneTextValue =
        buttonText !== '' && buttonText !== undefined && buttonText !== null
          ? buttonText
          : t('tourism.landing.firstSection.needCargoButton');
      setNeedCargoText(buttoneTextValue);
    }
    if (slides.length === 0 && !noSlideFound && finishedFirstFetch) {
      setNeedCargoText(t('tourism.landing.firstSection.needCargoButton'));
      setNoSlideFound(true);
    }
  };

  const updateSettingsTextValues = () => {
    if (noSlideFound && finishedFirstFetch) {
      const buttonText =
        language === 'en' ? 'landingHeroNeedCargoEn' : 'landingHeroNeedCargoEs';
      const buttoneTextValue =
        settings[buttonText] ||
        t('tourism.landing.firstSection.needCargoButton');
      setNeedCargoText(buttoneTextValue);
    }
  };

  const requestDefaultSlide = () => {
    if (noSlideFound && finishedFirstFetch) {
      indexSlidesRequest({
        dispatch,
        params: {
          actives: true,
          sort_column: 'position',
          sort_direction: 'asc',
          display_length: 5,
          slide_type: 'principal_banner'
        },
        successCallback: result => {
          setSlides(camelCaseRecursive(result.data.data));
        }
      });
    }
  };

  const bookTrip = {
    route: '',
    month: '',
    year: '',
    adults: 0,
    children: 0,
    infants: 0
  };

  const handleClick = () => {
    history.push('/cargo');
  };

  const slidesRequest = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: 5,
        slide_type: slideType
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
        setFinishedFirstFetch(true);
      }
    });
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  useEffect(slidesRequest, []);
  useEffect(updateSlideTextValues, [language, slides, finishedFirstFetch]);
  useEffect(updateSettingsTextValues, [settings]);
  useEffect(requestDefaultSlide, [noSlideFound, finishedFirstFetch]);

  return (
    <Row className="hero" style={{ overflow: 'hidden' }}>
      <Slides items={slides} />
      <Col sm={12} className="align-self-center container-hero__content">
        <Row className="align-items-center justify-content-center">
          <Col style={{ marginTop: '16rem' }}>
            <h1 className="title-landing">
              {slideTitle !== '' &&
              slideTitle !== undefined &&
              slideTitle !== null
                ? slideTitle
                : title}
            </h1>
            {((slideSubtitle !== '' &&
              slideSubtitle !== undefined &&
              slideSubtitle !== null) ||
              subtitle) && (
              <h6
                style={{ textTransform: 'none' }}
                className="text-white text-justify text-center"
              >
                {slideSubtitle !== '' &&
                slideSubtitle !== undefined &&
                slideSubtitle !== null
                  ? slideSubtitle
                  : subtitle}
              </h6>
            )}
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center mt-5 flex-column">
          <Col
            xs={isDesktop ? 8 : 11}
            md={isDesktop ? 12 : 8}
            lg={10}
            xl={9}
            id="reserve"
            className="p-4 form-booktrip"
          >
            <h6 className="mb-2 title-booktrip">
              {t('tourism.landing.firstSection.bookTripTitle')}
            </h6>
            <FormBookTrip
              modal={!isDesktop}
              setShowModal={setShowModal}
              modelName="bookTrip"
              bookTrip={bookTrip}
              formRequest={handleCreateRequest}
            />
          </Col>
          {!dontShowCargo && (
            <Col md={4} xl={3} className="mt-5 d-flex justify-content-center">
              <Button
                type="submit"
                variant="primary"
                className="d-flex justify-content-center align-items-center p-2 w-75"
                onClick={handleClick}
              >
                {needCargoText}
                <div className="box-icon-truck">
                  <IcoMoon icon="truck" size={14} />
                </div>
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default FirstSection;
