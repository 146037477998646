import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useWindowSize, useScrollPosition } from '../../hooks';
import { CenteredModal, FormBookTrip, SubHeader } from '../../components';
import GuidesSection from '../Home/Landing/GuidesSection';
import { redirectToReservation } from '../../services/utils';
import FirstSection from '../Home/Landing/FirstSection';
import Rates from '../../components/TourismRates/Rates';
import SEO from '../../components/Utils/SEO/SEO';

const TourismRates = () => {
  const [isDesktop, setIsDesktop] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showSubHeader, setShowSubHeader] = useState(false);
  const { windowSize } = useWindowSize();
  const { t, i18n } = useTranslation();
  const { settings } = useSelector(state => state.utils);
  const routesBreakdownRef = useRef(null);
  const { scrollPosition } = useScrollPosition();

  const evaluatePosition = () => {
    if (routesBreakdownRef.current !== null) {
      const { offsetTop } = routesBreakdownRef.current;
      if (scrollPosition.y >= offsetTop + 400) {
        setShowSubHeader(true);
      } else {
        setShowSubHeader(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(evaluatePosition, [scrollPosition]);

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const language = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    if (windowSize.width > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowSize.width]);

  const bookTrip = {
    route: '',
    month: '',
    year: '',
    adults: 0,
    children: 0,
    infants: 0
  };

  return (
    <>
      <SEO
        title={t('tourism.rateSection.seo.title')}
        description={t('tourism.rateSection.seo.description')}
      />
      <div ref={routesBreakdownRef} />
      <FirstSection
        isDesktop={isDesktop}
        setShowModal={setShowModal}
        handleCreateRequest={redirectToReservation}
        title={t('tourism.rateSection.body.hero')}
        slideType="tourism_rate"
      />
      <SubHeader
        showSubHeader={showSubHeader}
        handleCreateRequest={redirectToReservation}
      />
      <Rates />
      <GuidesSection settings={settings} />
      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <div className="px-2 pb-2 modal-booktrip">
            <FormBookTrip
              defaultStyleColumns
              showModal={showModal}
              setShowModal={setShowModal}
              modelName="bookTrip"
              sizeColumns={[12, 12, 12, 12, 12, 12, 12]}
              bookTrip={bookTrip}
              formRequest={redirectToReservation}
            />
          </div>
        }
      />
    </>
  );
};

export default TourismRates;
