import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga4';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import createRootReducer from '../reducers';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_4_KEY);

export const history = createBrowserHistory();

const sagaMW = createSagaMiddleware();
const routerMW = routerMiddleware(history);
const middleware = [sagaMW, routerMW];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(history),
  composeEnhancer(applyMiddleware(...middleware))
);

sagaMW.run(rootSaga);

export default store;
