/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import {
  FormikInput,
  FormikSelect,
  FormikCheckBox,
  UploadFile,
  IcoMoon
} from '../../../components';
import VideoPlayer from '../../../components/Videos/VideoPlayer';

const VideoForm = props => {
  const { onHide, submitVariant, errors, touched, action, video } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [videoUrl, setVideoUrl] = useState(undefined);

  const { videoMovie } = values.video;
  useEffect(() => {
    setVideoUrl(videoMovie?.fileUrl);
  }, [videoMovie]);

  function getVideoUrl(pickedVideo) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = error => {
        reject(error);
      };
      reader.readAsDataURL(pickedVideo);
    });
  }

  return (
    <Form className="w-100 px-2">
      <Row>
        <Col>
          <VideoPlayer src={videoUrl} />
          <Field name="video[videoMovie]">
            {({ field }) => {
              return (
                <UploadFile
                  {...field}
                  abbr
                  label="Archivo de video"
                  iconComponent={
                    <div className="circle-image">
                      <IcoMoon icon="files" size={20} />
                    </div>
                  }
                  accept="video/*"
                  setFieldValue={setFieldValue}
                  fileName={getIn(videoMovie, 'filename')}
                  onChange={async pickedVideo => {
                    setFieldValue('video[videoMovie]', pickedVideo);
                    const newVideoUrl = await getVideoUrl(pickedVideo); // Obtén la URL del video
                    setVideoUrl(newVideoUrl); // Actualiza el campo de videoUrl en el formulario
                  }}
                  error={getIn(errors, 'video[videoMovie]')}
                  touched={getIn(touched, 'video[videoMovie]')}
                />
              );
            }}
          </Field>
        </Col>
        <Col>
          <Field name="video[title]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Título"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
          <Row className="align-items-end">
            <Col>
              <Field name="video[position]">
                {({ field }) => {
                  const { name } = field;
                  return (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="number"
                      label="Posición"
                      error={getIn(errors, name)}
                      touched={getIn(touched, name)}
                    />
                  );
                }}
              </Field>
            </Col>
            <Col md={2} className="mb-3">
              <Field name="video[active]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Activo"
                    custom
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Field name="video[description]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="textarea"
                  isTextArea
                  label="Descripción"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  rows={5}
                />
              );
            }}
          </Field>
          <Field name="video[language]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Idioma"
                placeholder="Seleccionar Idioma"
                options={[
                  { label: 'Español', value: 'es' },
                  { label: 'Inglés', value: 'en' }
                ]}
                defaultValue={video.language}
                onChange={data =>
                  setFieldValue('video[language]', data ? data.value : '')
                }
                setFieldTouched={() => setFieldTouched('video[language]')}
                isSearchable={false}
                error={getIn(errors, 'video[language]')}
                touched={getIn(touched, 'video[language]')}
              />
            )}
          </Field>
          <Field name="video[location]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Locación"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mb-3">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    title,
    description,
    position,
    language,
    location,
    active,
    video
  } = props.video;
  return {
    video: {
      id,
      title,
      description,
      position,
      language,
      location,
      active,
      videoMovie: video
    }
  };
};

const validationSchema = Yup.object().shape({
  video: Yup.object().shape({
    title: Yup.string().required('El título es requerido'),
    description: Yup.string().required('La descripción es requerida'),
    language: Yup.string().required('El idioma es requerido'),
    location: Yup.string().required('La locación es requerida'),
    videoMovie: Yup.mixed().required('Debes seleccionar un video'),
    active: Yup.boolean(),
    position: Yup.number()
      .integer()
      .required('La posición es requerida')
  })
});

const handleSubmit = (values, { props }) => {
  const { videoMovie } = values.video;
  const paramsToSend = {
    video: snakecaseKeys({ ...values.video })
  };
  if (videoMovie) paramsToSend.video.video = videoMovie;
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(VideoForm);
