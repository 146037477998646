import React from 'react';
import TravelSale from '../../../assets/images/Travel-sale.webp';

const FixedButton = () => {
  return (
    <div className="fixed-button">
      <img src={TravelSale} alt="" />
    </div>
  );
};

export default FixedButton;
