import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import camelCaseRecursive from 'camelcase-keys-recursive';
import axios from 'axios';

import { useWindowSize } from '../../hooks';
import { FormBookTrip, CenteredModal, SubHeader } from '../../components';
import ImageAndText from '../../components/Utils/Sections/ImageAndText';
import FirstSection from '../Home/Landing/FirstSection';
import GuidesSection from '../Home/Landing/GuidesSection';
import SlidesAndText from '../../components/Utils/Sections/SlidesAndText';
import AvailableFacilities from '../../components/OurFerries/AvailableFacilities';
import FerryCharacteristics from '../../components/OurFerries/FerryCharacteristics';
import { indexRoutesRequest } from '../../requests/routes';
import indexSettingRequest from '../../requests/settings';

import SEO from '../../components/Utils/SEO/SEO';
import { redirectToReservation } from '../../services/utils';
import ferryEsperanza from '../../assets/images/ferry-esperanza.jpeg';

const bookTrip = {
  route: '',
  month: '',
  year: '',
  adults: 0,
  children: 0,
  infants: 0
};

const OurFerries = () => {
  const language = localStorage.getItem('language') || 'es';
  const [showModal, setShowModal] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);
  const [showSubHeader, setShowSubHeader] = useState(false);
  const { windowSize } = useWindowSize();
  const [settings, setSettings] = useState({});
  const { t } = useTranslation();
  const [newFerryData, setNewFerryData] = useState({});
  const [facilitiesData, setFacilitiesData] = useState({});
  const [ferryCharacteristics, setFerryCharacteristics] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(undefined);
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const settingsRequestWrapper = () => {
    indexSettingRequest({
      dispatch,
      successCallback: result => {
        setSettings(camelCaseRecursive(result.data));
      }
    });
  };

  const routesRequestWrapper = () => {
    indexRoutesRequest({
      dispatch,
      params: {
        actives: true,
        route_type_filter: 'both',
        sort_direction: 'asc',
        sort_column: 'position',
        language: localStorage.getItem('language'),
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        setSelectedRoute(camelCaseRecursive(result.data.data)[0]);
      }
    });
    return () => cancelToken.cancel();
  };

  const setTranslationsData = () => {
    let title = '';
    let description = '';

    // new ferry data
    const newFerryTitleKey =
      language === 'en'
        ? 'ourFerriesFirstSectionTitleEn'
        : 'ourFerriesFirstSectionTitleEs';
    const newFerryDescriptionKey =
      language === 'en'
        ? 'ourFerriesFirstSectionBodyEn'
        : 'ourFerriesFirstSectionBodyEs';

    title = settings[newFerryTitleKey] || '';
    description = settings[newFerryDescriptionKey] || '';
    setNewFerryData({
      title,
      description
    });

    // FerryCharacteristics
    const firstImageUrlKey = 'ourFerriesSecondSectionFirstImage';
    const firstImageTitleKey =
      language === 'en'
        ? 'ourFerriesSecondSectionFirstImageTitleEn'
        : 'ourFerriesSecondSectionFirstImageTitleEs';
    const firstImageDescriptionKey =
      language === 'en'
        ? 'ourFerriesSecondSectionFirstImageDescriptionEn'
        : 'ourFerriesSecondSectionFirstImageDescriptionEs';

    const firstImage = {
      imageUrl: settings[firstImageUrlKey] || '',
      title: settings[firstImageTitleKey] || '',
      description: settings[firstImageDescriptionKey]
    };

    const secondImageUrlKey = 'ourFerriesSecondSectionSecondImage';
    const secondImageTitleKey =
      language === 'en'
        ? 'ourFerriesSecondSectionSecondImageTitleEn'
        : 'ourFerriesSecondSectionSecondImageTitleEs';
    const secondImageDescriptionKey =
      language === 'en'
        ? 'ourFerriesSecondSectionSecondImageDescriptionEn'
        : 'ourFerriesSecondSectionSecondImageDescriptionEs';

    const secondImage = {
      imageUrl: settings[secondImageUrlKey] || '',
      title: settings[secondImageTitleKey] || '',
      description: settings[secondImageDescriptionKey]
    };

    const thirdImageUrlKey = 'ourFerriesSecondSectionThirdImage';
    const thirdImageTitleKey =
      language === 'en'
        ? 'ourFerriesSecondSectionThirdImageTitleEn'
        : 'ourFerriesSecondSectionThirdImageTitleEs';
    const thirdImageDescriptionKey =
      language === 'en'
        ? 'ourFerriesSecondSectionThirdImageDescriptionEn'
        : 'ourFerriesSecondSectionThirdImageDescriptionEs';

    const thirdImage = {
      imageUrl: settings[thirdImageUrlKey] || '',
      title: settings[thirdImageTitleKey] || '',
      description: settings[thirdImageDescriptionKey]
    };

    const fourthImageUrlKey = 'ourFerriesSecondSectionFourthImage';
    const fourthImageTitleKey =
      language === 'en'
        ? 'ourFerriesSecondSectionFourthImageTitleEn'
        : 'ourFerriesSecondSectionFourthImageTitleEs';
    const fourthImageDescriptionKey =
      language === 'en'
        ? 'ourFerriesSecondSectionFourthImageDescriptionEn'
        : 'ourFerriesSecondSectionFourthImageDescriptionEs';

    const fourthImage = {
      imageUrl: settings[fourthImageUrlKey] || '',
      title: settings[fourthImageTitleKey] || '',
      description: settings[fourthImageDescriptionKey]
    };

    setFerryCharacteristics([firstImage, secondImage, thirdImage, fourthImage]);

    // facilities data
    const facilitiesTitleKey =
      language === 'en'
        ? 'ourFerriesThirdSectionTitleEn'
        : 'ourFerriesThirdSectionTitleEs';
    const facilitiesDescriptionKey =
      language === 'en'
        ? 'ourFerriesThirdSectionDescriptionEn'
        : 'ourFerriesThirdSectionDescriptionEs';

    title = settings[facilitiesTitleKey] || '';
    description = settings[facilitiesDescriptionKey] || '';
    setFacilitiesData({
      title,
      description
    });
  };

  const openBrochure = () =>
    window.open(selectedRoute.attachedBrochure.fileUrl);

  useEffect(() => {
    if (windowSize.width > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowSize.width]);

  const handleCloseModal = () => setShowModal(false);
  useEffect(setTranslationsData, [settings, language]);
  useEffect(routesRequestWrapper, [language]);
  useEffect(settingsRequestWrapper, []);

  return (
    <>
      <SEO
        title={t('tourism.ourFerries.seo.title')}
        description={t('tourism.ourFerries.seo.description')}
      />
      <FirstSection
        bookTrip={bookTrip}
        isDesktop={isDesktop}
        setShowModal={setShowModal}
        handleCreateRequest={redirectToReservation}
        title={t('tourism.ourFerries.hero.title')}
        slideType="tourism_our_ferries"
      />

      <SubHeader
        bookTrip={bookTrip}
        showSubHeader={showSubHeader}
        handleCreateRequest={redirectToReservation}
      />

      <MarginWrapper
        childComponent={
          <ImageAndText
            isDesktop={isDesktop}
            title={newFerryData.title}
            description={newFerryData.description}
            btnText={t('tourism.ourFerries.common.brochure')}
            srcImg={ferryEsperanza}
            onClick={openBrochure}
          />
        }
      />
      <div className="our-ferries-characteristics">
        <FerryCharacteristics
          data={ferryCharacteristics}
          setShowSubHeader={setShowSubHeader}
          onClick={openBrochure}
        />
      </div>

      <AvailableFacilities isDesktop={isDesktop} />

      <MarginWrapper
        childComponent={
          <SlidesAndText
            isDesktop={isDesktop}
            title={facilitiesData.title}
            description={facilitiesData.description}
            slideType="our_ferries_deck"
          />
        }
      />

      <GuidesSection settings={settings} />

      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <div className="px-2 pb-2 modal-booktrip">
            <FormBookTrip
              defaultStyleColumns
              showModal={showModal}
              setShowModal={setShowModal}
              modelName="bookTrip"
              sizeColumns={[12, 12, 12, 12, 12, 12, 12]}
              bookTrip={bookTrip}
              formRequest={redirectToReservation}
            />
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = state => {
  const { slides } = state.utils;
  return {
    slides
  };
};

export default connect(mapStateToProps)(OurFerries);

const MarginWrapper = ({ childComponent }) => {
  return (
    <Row className="mt-4 mb-5 align-items-center justify-content-center">
      <Col md={10}>{childComponent}</Col>
    </Row>
  );
};
