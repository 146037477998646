import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { NavbarTop, Footer, FixedButton } from '../../components';

const PublicLayout = ({ component: Component, ...rest }) => {
  const className = () => {
    if ({ ...rest }.path !== '/') return 'import-page-layout';
    return '';
  };
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarTop />
            <FixedButton />
            <Container className={`container-layout ${className()}`} fluid>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        ) : (
          <>
            <NavbarTop />
            <FixedButton />
            <Container className={`container-layout ${className()}`} fluid>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        )
      }
    />
  );
};

export default PublicLayout;
