import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FormBookTrip from '../BookTrip/FormBookTrip';

const SubHeader = ({
  showSubHeader = false,
  handleCreateRequest
}) => {
  const bookTrip = {
    route: '',
    month: '',
    year: '',
    adults: 0,
    children: 0,
    infants: 0
  };
  return (
    <Row
      className={`container-subheader justify-content-center pt-2 ${
        showSubHeader ? 'subheader show' : 'subheader'
      }`}
    >
      <Col md={12} className="d-flex justify-content-center">
        <div style={{ width: '83%', marginTop: -3 }}>
          <FormBookTrip
            modelName="bookTrip"
            bookTrip={bookTrip}
            formRequest={handleCreateRequest}
            classBtn="btn-reservation"
            classContainerForm="box-subheader"
          />
        </div>
      </Col>
    </Row>
  );
};

export default SubHeader;
