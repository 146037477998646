import React, { useRef, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import mapImg from '../../../assets/images/Map.svg';
import RouteCard from '../../../components/Landing/Routes/RouteCard';
import { useScrollPosition } from '../../../hooks';
import { uuidV4 } from '../../../services/utils';
import '../../../assets/stylesheets/components/routes/RouteSection.scss';

const RoutesSection = ({
  routes,
  setShowSubHeader,
  availableTourismItineraries,
  tourismSeasonStart,
  tourismSeasonEnd
}) => {
  const { t } = useTranslation();
  const sectionThirdRef = useRef();
  const { scrollPosition } = useScrollPosition();

  const evaluatePosition = () => {
    if (sectionThirdRef.current !== null) {
      const { offsetTop } = sectionThirdRef.current;
      if (scrollPosition.y >= offsetTop - 80) {
        setShowSubHeader(true);
      } else {
        setShowSubHeader(false);
      }
    }
  };
  useEffect(evaluatePosition, [scrollPosition]);

  return (
    <Row className="route-section" ref={sectionThirdRef}>
      <Col sx={12}>
        <h3 className="text-center text-primary">
          {t('tourism.landing.routesSection.transportationTitle')}
        </h3>
        <h6 className="text-center mt-3 mb-4">
          {t('tourism.landing.routesSection.transportationSubtitle')}
        </h6>
        <div className="route-section-container">
          <Image src={mapImg} className="map-img" />
          {routes.map(route => {
            return (
              <React.Fragment key={uuidV4()}>
                <RouteCard
                  key={uuidV4()}
                  route={route}
                  availableTourismItineraries={availableTourismItineraries}
                  tourismSeasonStart={tourismSeasonStart}
                  tourismSeasonEnd={tourismSeasonEnd}
                />
                <RouteCard
                  key={uuidV4()}
                  route={route}
                  availableTourismItineraries={availableTourismItineraries}
                  tourismSeasonStart={tourismSeasonStart}
                  tourismSeasonEnd={tourismSeasonEnd}
                  isDestination
                />
              </React.Fragment>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default RoutesSection;
